/* You can add global styles to this file, and also import other style files */
.ui-datepicker
{
  z-index: 999999 !important;
}
.star {
    color: red;
    font-weight: 300;
}

.rounded .mb-3 {
  background: #fff !important;
}

body .ui-table .ui-table-tbody > tr > td {
  text-align: center !important;
}
body .ui-table .ui-table-thead > tr > th {
  text-align: center !important;
}
body .pi {
  font-size: 12px !important;
}